import { AdminSharedScripts } from '../pages/Admin/AdminSharedScripts';

function SharedScriptsRoute() {
  return <AdminSharedScripts />;
}

export const Component = SharedScriptsRoute;

export function clientLoader() {
  return null;
}
